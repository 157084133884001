const List = () => {
	return (
		<svg style={{ verticalAlign: 'middle' }} width={24} height={24} fill="#1D1D1D">
			<circle cx={5} cy={12} r={2} />
			<circle cx={5} cy={19} r={2} />
			<circle cx={5} cy={5} r={2} />
			<path d="M10 4h11v2H10zm0 7h11v2H10zm0 7h11v2H10z" />
		</svg>
	)
}

export default List
