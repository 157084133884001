import React from 'react'

const Merchant = () => {
	return (
		<svg
			width="105"
			height="89"
			viewBox="0 0 105 89"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.64158 33.9742C2.64158 32.9412 3.1405 31.9717 3.98116 31.3712L2.86381 29.8069L3.98116 31.3712L35.0397 9.18649L33.9011 7.59242L35.0397 9.18649C36.152 8.39203 37.6461 8.39202 38.7584 9.18649L69.8169 31.3712C70.6576 31.9717 71.1565 32.9412 71.1565 33.9742V86.2736H2.64158V33.9742Z"
				stroke="black"
				strokeWidth="3.95503"
			/>
			<path
				d="M23.3506 86.2744V59.1709H50.4541V86.2744H23.3506Z"
				stroke="black"
				strokeWidth="3.95503"
			/>
			<path
				d="M80.2881 13.1933C80.2881 7.13826 85.1966 2.22971 91.2516 2.22971C97.3066 2.22971 102.215 7.13826 102.215 13.1933V86.274H80.2881V13.1933Z"
				stroke="black"
				strokeWidth="3.95503"
			/>
			<path d="M50.2754 61.0459L25.5565 85.7648" stroke="black" strokeWidth="3.95503" />
			<path d="M48.957 84.7769L24.2531 60.0729" stroke="black" strokeWidth="3.95503" />
			<mask id="path-6-inside-1" fill="white">
				<rect x="88.6641" y="19.583" width="15.5293" height="14.1487" rx="1.37499" />
			</mask>
			<rect
				x="88.6641"
				y="19.583"
				width="15.5293"
				height="14.1487"
				rx="1.37499"
				stroke="black"
				strokeWidth="7.91006"
				mask="url(#path-6-inside-1)"
			/>
			<mask id="path-7-inside-2" fill="white">
				<rect x="88.6641" y="37.8567" width="15.5293" height="14.1487" rx="1.37499" />
			</mask>
			<rect
				x="88.6641"
				y="37.8567"
				width="15.5293"
				height="14.1487"
				rx="1.37499"
				stroke="black"
				strokeWidth="7.91006"
				mask="url(#path-7-inside-2)"
			/>
			<path
				d="M102.047 66.252H91.0469"
				stroke="black"
				strokeWidth="3.95503"
				strokeLinecap="round"
			/>
			<path
				d="M102.047 71.752H91.0469"
				stroke="black"
				strokeWidth="3.95503"
				strokeLinecap="round"
			/>
			<path
				d="M102.047 77.252H91.0469"
				stroke="black"
				strokeWidth="3.95503"
				strokeLinecap="round"
			/>
			<mask id="path-11-inside-3" fill="white">
				<rect x="26.5488" y="36.4873" width="20.7057" height="15.5293" rx="1.37499" />
			</mask>
			<rect
				x="26.5488"
				y="36.4873"
				width="20.7057"
				height="15.5293"
				rx="1.37499"
				stroke="black"
				strokeWidth="7.91006"
				mask="url(#path-11-inside-3)"
			/>
		</svg>
	)
}

export default Merchant
