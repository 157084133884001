const Hands = () => {
	return (
		<svg width={200} height={106} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 106">
			<path
				fill="#084646"
				d="M84.3 31.1c3.8.5 7.6.4 11.3 1.6-.5-.2.1.1.2.1.2.1.5.3.7.4.5.3 1.1.6 1.6 1s1 .7 1.5 1.1c-.3-.2.2.1.2.2.1.1.2.2.3.2.3.3.7.6 1 .8 2.2 1.9 4.3 3.8 6.3 5.9.8.8 1.6 1.7 2.3 2.6.2.2.4.5.6.7.2.3-.3-.5 0-.1.1.1.2.2.2.3 5.4 7.2 10.6 14.5 15.8 21.9 2.6 3.6 5.2 7.3 7.5 11 .5.9 1.7 1.2 2.6.7.9-.5 1.2-1.7.7-2.6-2.1-3.3-4.4-6.5-6.6-9.7-3-4.3-6.1-8.7-9.2-13-2.4-3.3-4.8-6.6-7.2-9.8-2.7-3.6-5.9-6.8-9.3-9.8-2.3-2-4.7-4.1-7.5-5.4-4.1-1.9-8.8-1.6-13.1-2.1-1-.1-1.9 1-1.9 1.9.1 1.4 1 2 2 2.1z"
			/>
			<path
				fill="#f0694b"
				d="M135.6 79.9c1.8-.2 3.6-.6 5.3-1.5 1.3-.7 2.5-1.8 3.6-2.9 1.2-1.2 2.4-2.4 3.8-3.4.7-.5 1.5-.9 2.4-1 1.1-.1 2.1.1 3.2.2 9.4.9 18.9.8 28.4.3 5.3-.3 10.6-.6 16-1 1-.1 1.9-.8 1.9-1.9 0-1-.9-2-1.9-1.9-9.4.6-18.8 1.3-28.2 1.3-4.8 0-9.5-.1-14.2-.5-2.2-.2-4.6-.7-6.8-.1-2.1.5-3.8 2.1-5.4 3.5-.7.6-1.3 1.3-2 1.9-.3.3-.6.6-.9.8l-.4.4-.2.2c-.2.2.2-.2 0 0-.3.2-.6.4-1 .6-.2.1-.3.2-.5.3-.1 0-.2.1-.2.1-.3.1.2-.1 0 0-.4.2-.8.3-1.3.4-.5.1-.9.2-1.3.2-1 .1-1.9.8-1.9 1.9-.3 1.2.6 2.2 1.6 2.1z"
			/>
			<path
				fill="#084646"
				d="M1.4 58.2c8.8 2.8 17.7 5.5 26.5 8.2 2 .6 4.3 1.2 6.1 2.1 2 1 3.7 2.5 5.8 3.4 2.4 1.1 5 1.2 7.6 1.2 1.4 0 2.8-.1 4.3 0 .7.1 2 .4 2.6.7.2.1.5.2.7.4.1.1.6.4.4.3.2.2.4.3.6.5l.3.3c.1.2.3.4.4.6.1.1.1.2.2.3-.1-.2 0 0 0 .1.1.2.1.5.2.7 0 .2 0 0 0 0v.6c0 1.6 2.1 2.5 3.2 1.3l.5-.5c-.3.2.1-.1.2-.1l.6-.3c.1 0 .5-.2.2-.1.4-.2.8-.3 1.2-.4.1 0 .8-.1.4-.1h1.8c.2 0 .2 0 0 0 .1 0 .2 0 .4.1.2 0 .4.1.5.1.9.2 1.2.3 2 .9.1.1.1.1 0 0 .1.1.2.1.3.2.2.1.3.3.4.5.1.1.4.6.6 1 .3.6.6 1.6.7 2.3.2 1.7-.2 3.4-1 4.9-.7 1.1 0 2.4 1.1 2.8 1.2.4 2 .7 3 1.3.9.5 1.6 1 2.1 2 1.2 2.3 2.3 4.6 3.8 6.7 1.6 2.2 3.6 4.1 6.2 5 2.4.8 5 .4 7.1-1 2-1.4 3.9-3.7 3.9-6.3 0-2.4-3.8-2.4-3.8 0v.2c0 .2-.1.2 0-.1-.1.1-.1.3-.1.5 0 .1-.1.2-.1.3-.1.3.1-.3 0 0l-.3.6c-.1.1-.1.2-.2.3 0 0-.2.3-.1.2.1-.1-.2.3-.3.4l-.5.5c-.1.1-.2.2-.3.2.2-.1 0 0-.1.1-.2.1-.4.3-.7.4-.3.1-.3.2-.5.2-.2.1-.4.1-.7.2 0 0-.4.1-.2 0h-1c-.3 0 .3.1-.1 0-.1 0-.2 0-.3-.1-.5-.1-1-.3-1.3-.4-1.9-.9-3.1-2.5-4.3-4.4-.6-1-1.2-2.1-1.8-3.2-.5-1-.9-2-1.6-2.9-1.7-2.3-4.3-3.3-6.9-4.3.4.9.8 1.9 1.1 2.8 2-3.4 2.2-8 .1-11.4-1.9-3.2-5.7-4.7-9.3-4.2-2.1.2-4.1 1-5.5 2.6 1.1.4 2.2.9 3.2 1.3 0-4.9-4.4-7.9-8.9-8.3-2.6-.2-5.1.1-7.7-.1-2.3-.2-4.1-1.4-6-2.6-1.5-.9-2.9-1.8-4.6-2.4-1-.3-2-.7-3.1-1-3.6-1.1-7.3-2.2-11-3.4-5.7-1.8-11.5-3.5-17.2-5.4-1-.3-2.1.4-2.3 1.3-.1 1.1.5 2.1 1.5 2.4z"
			/>
			<path
				fill="#f0694b"
				d="M32.6 65.9c-.7 1.2-.9 2.7-1.1 4.1-.2 1.8.2 3.8 1.5 5.2 1.5 1.6 4 2.5 6.2 2.6 2 .1 4.1-.5 5.8-1.5 1.5-.9 3.1-2.2 3.9-3.8.4-.9.2-2.1-.7-2.6-.8-.5-2.1-.2-2.6.7-.1.1-.1.3-.2.4-.1.1-.4.5-.1.1-.2.3-.5.6-.8.9-.1.1-.2.2-.4.3-.2.1-.2.1 0 0-.1.1-.2.1-.3.2-.4.3-.8.5-1.2.7-.5.3-.4.2-.9.4s-1 .3-1.5.4h-1.5-.1c-.3-.1-.6-.1-.8-.2-.5-.2-.9-.3-1.4-.7-.4-.3-.6-.3-.9-.7.3.4-.1-.2-.1-.3.2.4 0-.2-.1-.3 0-.2-.1-.3-.1-.5.1.5 0-.2 0-.3 0-.4 0-.9.1-1.3 0-.2 0-.2 0 0 0-.1 0-.2.1-.3 0-.1.1-.3.1-.4.1-.3.2-.7.3-1 0-.1.2-.3.1-.4 0 .2-.2.4 0 .1 1.1-2-2.1-3.9-3.3-1.8z"
			/>
			<path
				fill="#f0694b"
				d="M46 70.1c-1.9 2-3.1 4.8-3.5 7.6-.4 2.5-.4 5.5 1.4 7.6 2 2.3 5.4 3.1 8.2 2.1 1.9-.7 3.1-2.3 4.4-3.8.9-1 1.8-2.1 2.7-3.1.7-.8 1.4-1.6 1.9-2.5.5-.9.2-2.1-.7-2.6-.9-.5-2.1-.2-2.6.7-.1.2-.3.4-.4.7-.3.4.2-.2-.1.2-.1.2-.3.4-.4.6L54.8 80c-.7.8-1.4 1.5-2.1 2.3-.3.3-.5.6-.8.9-.1.1-.6.5-.6.6 0-.2.4-.3.2-.1-.1 0-.1.1-.2.1-.2.1-.4.2-.1 0-.2.1-.4.1-.6.2-.2 0-.8.1-.4.1-1.2.1-1.8 0-2.9-.7-.2-.2-.4-.3-.6-.5l-.2-.2c.1.1.2.3.1.1 0-.1-.3-.6-.2-.4.1.3 0-.1-.1-.2 0-.1-.1-.3-.1-.4 0-.2-.1-.4-.1-.6.1.5 0-.3 0-.5v-1.6c0-.1.1-.5 0-.1 0-.2.1-.4.1-.5.1-.3.1-.6.2-.9.2-.6.4-1.3.6-1.9.1-.3-.2.4.1-.2.1-.2.2-.3.2-.5.1-.3.3-.5.4-.8.2-.3.3-.5.5-.7.3-.5-.1.2.1-.1.1-.2.3-.3.4-.4.7-.7.7-1.9 0-2.7-.7-.9-2-.9-2.7-.2z"
			/>
			<path
				fill="#f0694b"
				d="M51.3 84.9c.3 2.1.1 4.2 1.2 6.1 1.1 2 3.1 3.2 5.3 3.5 1.6.2 3.5.3 5.1-.3 1.4-.5 2.8-1.1 4.2-1.8 1.7-.7 3.5-1.5 4.9-2.7.8-.7.7-2 0-2.7-.8-.8-1.9-.7-2.7 0-.2.2.4-.3 0 0-.1.1-.2.1-.3.2-.3.2-.6.3-.8.5-.7.4-1.3.7-2 1-.7.3-1.4.6-2.2 1-.2.1 0 0 .1 0-.1 0-.2.1-.3.1-.1.1-.3.1-.4.2-.3.1-.6.2-.8.3-.4.2-.8.3-1.2.4-.1 0-.3.1-.4.1-.3.1.3 0-.1 0s-.7.1-1.1 0h-1-.2c-.1 0-.2 0-.3-.1-.2 0-.4-.1-.6-.2-.2-.1-.4-.1-.6-.2.3.1-.1-.1-.2-.1-.1-.1-.5-.3-.4-.2l-.3-.3c-.7-.7-.9-1.6-1-2.4v-.1-.4-.7c0-.4 0-.9-.1-1.3-.1-1-.8-1.9-1.9-1.9-.9.1-2 1-1.9 2z"
			/>
			<path
				fill="#f0694b"
				d="M61.5 92.2c.4 4.3 3.3 8.4 7.7 9.2 2.1.4 4.1 0 6.1-.8 1.6-.7 3.3-1.9 4-3.7.4-.9.3-2-.7-2.6-.8-.5-2.2-.3-2.6.7-.1.3-.3.5-.4.8.2-.3-.2.2-.2.2l-.3.3c-.1.1-.1.1 0 0-.1 0-.1.1-.2.1-.1.1-.3.2-.4.3-.3.2-.6.4-1 .5.2-.1 0 0-.1 0-.2.1-.4.1-.6.2-.3.1-.6.2-.9.2-.5.1-1.3.1-1.7.1-1.4-.2-2.6-.9-3.5-2-.8-.9-1.3-2.4-1.4-3.5-.1-1-.8-1.9-1.9-1.9-1 0-2 .9-1.9 1.9z"
			/>
			<path
				fill="#084646"
				d="M86.3 87.6c1.3 2.5 2.7 5 4.1 7.4 1.3 2.3 2.7 4.6 4.3 6.7.6.8 1.6 1.2 2.6.7.8-.5 1.3-1.8.7-2.6-1.6-2.1-2.9-4.4-4.3-6.7-1.4-2.4-2.8-4.9-4.1-7.4-.5-.9-1.7-1.2-2.6-.7-1 .5-1.2 1.7-.7 2.6zM5.5 3.5c9.9 4.7 19.5 9.8 29.2 14.9 3.2 1.7 6.3 3.4 9.5 4.9 1 .5 1.9.9 3 1.3.8.3 1.7.4 2.5.5 1.6.2 3.1.3 4.7 0 3.1-.5 5.8-2.3 8.8-3.1 2.9-.8 6-.7 8.9 0 1 .2 2.1-.3 2.3-1.3.3-1-.3-2.1-1.3-2.3-2.9-.7-5.9-.8-8.8-.4-2.8.4-5.3 1.6-7.9 2.6-1.2.5-2.5.9-3.7 1-1.4.1-2.8-.2-4.2-.5l-.9-.3c-.1 0-.2-.1-.3-.1 0 0 .5.2.1 0-.2-.1-.5-.2-.7-.3-3-1.4-6-3-9-4.6-4.4-2.3-8.8-4.7-13.2-7C19 5.9 13.2 3 7.5.2c-1-.4-2.1-.2-2.6.7-.5.8-.3 2.2.6 2.6z"
			/>
			<path
				fill="#f0694b"
				d="M39.8 72c-2.1-.9-3.8-2.4-5.8-3.4l-.4-4.3c1.6.6 3.1 1.4 4.6 2.4 1.9 1.3 3.7 2.4 6 2.6 2.6.3 5.1-.1 7.7.1 3.4.3 6.8 2.2 8.2 5.2.9-.4 1.9-.7 3-.8 3.6-.4 7.4 1.1 9.3 4.2 1.7 2.8 1.9 6.3.8 9.4 1.8.8 3.6 1.8 4.8 3.5.6.9 1.1 1.9 1.6 2.9l-2 4.2c-1.5-2.1-1.2-2.1-2.4-4.4-.5-.9-1.1-1.4-2.1-2-1.1-.6-1.8-.9-3-1.3-1.1-.3-1.7-1.6-1.1-2.7.9-1.5 1.2-3.3 1-4.9-.1-.7-.4-1.7-.7-2.3-.2-.3-.5-.8-.6-1-.1-.2-.3-.3-.4-.5l-.2-.2c-.8-.6-1.1-.7-2-.9-.2 0-.4-.1-.5-.1h-.2-2c.4 0-.3.1-.4.1-.4.1-.7.2-1.1.3.1 0-.2.1-.3.1l-.6.3c-.1 0-.3.2-.3.2-.1.1-.3.2-.4.4-1.1 1.2-3.2.3-3.2-1.3v-.6c0-.2-.1-.5-.2-.7 0-.1-.2-.4 0-.1 0-.1-.1-.2-.2-.3-.1-.2-.2-.4-.4-.6l-.3-.3-.5-.5c.1.1-.3-.2-.4-.3-.2-.1-.4-.3-.7-.4-.7-.3-1.9-.6-2.6-.7-1.4-.1-2.8 0-4.3 0-2.7-.1-5.3-.2-7.7-1.3z"
			/>
			<path
				fill="#084646"
				d="M106 64.9c1.5 2.7 3.3 5.4 5 8 2.1 3.2 4.3 6.5 6.6 9.6 1.7 2.4 3.5 4.9 5.4 7.2.6.8 1.3 1.4 2.3 1.8 2 .8 4.6.3 6.6-.4 1.3-.5 2.5-1.2 3.5-2.2.9-1 1.5-2.2 1.9-3.5.8-2.5.9-5.4.2-7.9-.6-2.4-4.3-1.4-3.6 1 .1.2.1.4.2.7 0 .1.1.7.1.4.1.5.1 1 .1 1.5s0 1-.1 1.6c0-.3-.1.4-.1.5 0 .3-.1.5-.2.8-.1.2-.1.5-.2.7 0 .1-.3.7-.2.4l-.3.6c-.1.1-.1.2-.2.3 0 0-.3.4-.2.2l-.3.3c-.1.1-.5.4-.3.3-.4.3-1 .6-1.2.7 0 0-.4.2-.6.2-.2.1-.4.1-.7.2-.2.1-.5.1-.8.2-.1 0-.2 0-.4.1H127.1c-.4 0 0 0 0 0-.1 0-.2 0-.3-.1-.1 0-.2-.1-.3-.1-.3-.1.1.1 0 0-.1 0-.2-.1-.2-.1-.3-.1.3.3.3.2l-.1-.1-.3-.3c-.3-.3-.5-.6-.7-.9-.1-.2-.3-.4-.4-.5 0-.1-.3-.4-.1-.2.2.3-.1-.2-.2-.2-.4-.5-.8-1-1.2-1.6-.9-1.3-1.9-2.6-2.8-3.8-2.1-3-4.2-6-6.2-9.1-1.8-2.8-3.7-5.6-5.3-8.5-1.2-2-4.5-.1-3.3 2z"
			/>
			<path
				fill="#f0694b"
				d="M184.1 23.4c-13.1 5.1-27 8.1-41.1 8.9-4 .2-8 .2-12 .1.5.3 1.1.6 1.6.9-4.4-6.4-11.2-10.2-18.4-12.5-2-.6-3.8-1.4-5.7-2.4-1.8-1-3.7-1.9-5.7-2.6-3.7-1.3-7.5-2-11.4-2.4-4.4-.5-8.8-.5-13.1-.4.4.2.9.4 1.3.6-.4-.5-1.2-.7-1.8-.5-3.6 1.1-5.7 4.4-7.1 7.7-.7 1.6-1.2 3.3-2 4.9-.8 1.6-2 2.6-3 4.1-1.1 1.7-1.3 3.7-.3 5.5 1 1.8 2.9 2.8 4.9 3.1 2 .2 4-.3 5.9-1.1 1.8-.7 3.5-1.6 5.1-2.8 3.3-2.6 5.6-6.8 5.1-11.1-.1-1-.8-1.9-1.9-1.9-.9 0-2 .9-1.9 1.9.3 2.8-.8 5.4-2.9 7.4-1 1-2.2 1.7-3.5 2.3-1.3.6-2.9 1.2-4.4 1.4-.6.1-1.2 0-1.8-.1-.5-.1-.9-.4-1.2-.8 0 .1-.3-.6-.2-.4 0 0-.1-.6-.1-.3v-.4-.1c.2-.5-.2.2.1-.3.2-.3.3-.6.6-.9.7-1 1.9-2.3 2.6-3.6.8-1.4 1.3-3 1.9-4.6.6-1.5 1.1-2.8 2-4.2.7-1.1 1.8-2 3-2.3-.6-.2-1.2-.3-1.8-.5.7.8 2 .6 3 .6h2.7c1.8 0 3.6.1 5.4.2 3.5.2 7 .6 10.4 1.5 1.6.4 3.3.9 4.7 1.6 1.8.7 3.4 1.7 5.1 2.6 3.4 1.6 7.1 2.4 10.4 4 3 1.4 5.7 3.2 8 5.5.5.5 1 1.1 1.5 1.7.3.3.4.5.6.8.4.5.7 1.2 1.3 1.5.8.5 2.1.3 3.1.3h3.1c7.9 0 15.7-.7 23.5-2.1 8.7-1.5 17.2-3.9 25.4-7.1 1-.4 1.6-1.3 1.3-2.3-.3-1-1.4-1.8-2.3-1.4z"
			/>
			<path
				fill="#084646"
				d="M97.4 80.3c2 3.2 4 6.3 6.1 9.4 1.1 1.5 2.1 3.1 3.2 4.6 1 1.4 2 2.9 3.2 4.1 2.3 2.3 5.6 3.4 8.8 2.9 3-.5 5.6-2.8 6.7-5.6 1.3-3.4.3-7.1-1.2-10.2-.4-.9-1.8-1.2-2.6-.7-.9.6-1.1 1.7-.7 2.6.6 1.2 1.1 2.6 1.3 3.9v1.9c0 .1-.1.4 0 0-.1.3-.1.6-.2.9 0 .1-.1.3-.1.4-.1.3.2-.4 0 .1l-.3.6c-.1.1-.1.2-.2.3 0 0-.3.4-.2.2l-.2.2c-.1.1-.2.3-.4.4-.1.1-.6.5-.3.3-.2.2-.5.3-.7.5-.5.3-1 .5-1.7.6h-1.1-.1c-.3 0-.6-.1-.9-.2-.2 0-.3-.1-.5-.2l-.3-.1c-1.2-.5-1.8-1-2.6-1.8-.3-.3-.8-1-1.2-1.5-.5-.7-1-1.3-1.5-2-1-1.3-1.9-2.7-2.8-4-2.1-3.1-4.2-6.2-6.1-9.4-.5-.9-1.7-1.2-2.6-.7-1 .4-1.3 1.6-.8 2.5z"
			/>
			<path
				fill="#084646"
				d="M94.7 101.7c1.1 1.6 2.9 2.5 4.8 2.8 1.8.3 3.8.3 5.6-.3 3.9-1.3 6.9-6.2 5.9-10.3-.2-1-1.4-1.6-2.3-1.3-1 .3-1.6 1.3-1.3 2.3 0 .1.1.5 0 .2v1c.1-.4-.1.2-.1.3-.2.8-.3 1.1-.8 1.9-.4.7-.7 1-1.4 1.6l-.2.2c-.1.1-.3.2-.5.3-.2.1-.3.2-.5.2.3-.1.2-.1 0 0-.3.1-.6.2-.9.2-.1 0-.6.1-.1 0h-.5c-.7 0-1.4 0-2.1-.1.4.1-.1 0-.2 0-.2 0-.3-.1-.5-.1-.3-.1-.7-.2-1-.3.4.2-.1-.1-.2-.1-.1-.1-.3-.2-.4-.2l.1.1c-.1 0-.1-.1-.2-.1-.1-.1-.4-.5-.1-.1-.6-.8-1.7-1.2-2.6-.7-.7.3-1.1 1.6-.5 2.5z"
			/>
		</svg>
	)
}

export default Hands
