import React from 'react'
import styled from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { CLOUDINARY_BASE_URL } from '../../constants/constants'

// TODO
// Follow this every time in this migration to TS:
// https://github.com/styled-components/styled-components/issues/630#issuecomment-315475109

interface IResponsiveContainerProps {
	aspectRatio?: string
}

// TODO: Deprecated, to migrate to BasicImage!
const ResponsiveImage = (props) => {
	const width = props.width ? props.width : 800
	const height = props.height ? props.height : 800
	const aspectRatio = props.aspectRatio ? props.aspectRatio * 100 : (height / width) * 100

	return (
		<ResponsiveContainer aspectRatio={aspectRatio.toString()}>
			{props.altPath && props.altPath.length !== 0 && (
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				<ProgressiveImage src={props.altPath}>
					{(src) => <img src={src} alt="an image" />}
				</ProgressiveImage>
			)}
			{props.path && props.path.length !== 0 && (
				<ProgressiveImage
					src={`${CLOUDINARY_BASE_URL}/w_${width},h_${height},c_fill,q_auto,f_auto,dpr_2.0/${props.path}`}
					placeholder={`${CLOUDINARY_BASE_URL}/v1606130241/Placeholder_whvhnk.jpg`}
				>
					{(src, loading) => (
						<>
							<img style={{ opacity: loading ? 0.5 : 1 }} src={src} alt={props.path} />
							<noscript>
								<img
									src={`${CLOUDINARY_BASE_URL}/w_${width},h_${height},c_fill,dpr_2.0/${props.path}`}
								/>
							</noscript>
						</>
					)}
				</ProgressiveImage>
			)}
			{!props.path && !props.altPath && (
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				<ProgressiveImage src="/static/Placeholder.jpg">
					{(src) => <img src={src} alt="an image" />}
				</ProgressiveImage>
			)}
		</ResponsiveContainer>
	)
}

const ResponsiveContainer = styled.figure<IResponsiveContainerProps>`
	position: relative;
	overflow: hidden;
	padding-top: ${(props) => (props.aspectRatio ? props.aspectRatio : '100')}%;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: opacity 0.3s ease-in-out;
	}
`

interface IProps {
	width: number
	height: number
	path: string
	alt?: string
	external?: boolean
}

const defaultProductImage = 'v1606130241/Placeholder_whvhnk'

export const BasicImage = ({ width, height, path, alt, external = false }: IProps) => {
	const placeholder = getResponsiveImagePath({
		width,
		height,
		path: defaultProductImage,
	})

	const img = external
		? path
		: getResponsiveImagePath({
				width,
				height,
				path,
			})

	return (
		<ProgressiveImage src={img} placeholder={placeholder}>
			{(src, loading) => (
				<img style={{ opacity: loading ? 0.5 : 1, borderRadius: 1 }} src={src} alt={alt} />
			)}
		</ProgressiveImage>
	)
}

export const getResponsiveImagePath = ({ width, height, path }: IProps) => {
	return `${CLOUDINARY_BASE_URL}/w_${width},h_${height},c_fill,q_auto,f_auto,dpr_2.0/${path}.jpg`
}

export default ResponsiveImage
