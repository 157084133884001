const Graph = () => {
	return (
		<svg width={96} height={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 80">
			<path
				fill="#084646"
				d="M11.4 69.7c2.8-3.5 5.9-6.8 8.9-10.2 3.6-4 7.1-7.9 10.6-11.9 1.5-1.7 3-3.4 4.5-5.2h-2.1c3.1 3.2 5.6 6.8 7.5 10.8.2.5.8.7 1.3.7.6 0 1-.3 1.3-.7 3.6-5 7.5-9.8 11.5-14.5-.8-.1-1.5-.2-2.3-.3 3.1 4.4 6.1 8.8 9.1 13.2.6.9 2 1 2.5 0C70.8 39.2 77.4 26.7 84 14.3c1.9-3.5 3.7-7.1 5.6-10.6.4-.7.2-1.6-.5-2-.7-.4-1.6-.2-2 .5C80.5 14.7 74 27.1 67.4 39.6c-1.9 3.5-3.7 7.1-5.6 10.6h2.5c-3.1-4.4-6.1-8.8-9.1-13.2-.5-.7-1.7-1.1-2.3-.3-4.2 4.9-8.2 9.9-12 15.1h2.5c-2-4.2-4.7-8.1-7.9-11.4-.5-.5-1.6-.6-2.1 0-2.8 3.5-5.9 6.8-8.9 10.2-3.6 4-7.1 7.9-10.6 11.9-1.5 1.7-3 3.4-4.5 5.2-.5.6-.6 1.5 0 2.1.5.4 1.5.5 2-.1z"
			/>
			<path
				fill="#084646"
				d="M74.5 7.2c4.5-1.4 9-2.7 13.5-4.1.2 0 .7-.1.8-.2-.3 0-.5-.1-.7-.3.1.1.1.2.2.3.3.5.5 1 .8 1.5.5 1 1 2 1.4 3.1.9 2 1.7 4.1 2.6 6.1.3.7 1 1.3 1.8 1 .7-.2 1.3-1.1 1-1.8-1.5-3.6-2.9-7.3-4.7-10.7-.5-.9-.9-1.9-2-2.1-1-.2-2.2.4-3.2.7-1.9.6-3.8 1.1-5.7 1.7-2.2.7-4.4 1.3-6.5 2-.8.2-1.2 1-1 1.8.1.8.9 1.3 1.7 1z"
			/>
			<path
				d="M82.8 33c0 5-.1 10.1-.3 15.1-.2 5.3-.4 10.6-.7 15.9-.2 3.8-.4 7.6-.7 11.4 0 .5-.1 1-.1 1.5v.5c0 .2-.1.5 0-.1v.1c.1-.4.4-.7.8-.9.3-.1.2-.1.1 0 .3-.1.5 0 .1 0h-.3c-2 .1-4 .1-6.1.2-3.6.1-7.3.1-10.9.1-4.7 0-9.3.1-14 .1-5.2 0-10.4 0-15.7.1-5.2 0-10.4 0-15.6.1-4.7 0-9.3.1-14 .1h-3c.4.2.9.5 1.3.7-.1-.3.1.4.1.1 0-.1-.1-.2-.1-.2-.1-.2-.1-.4-.1-.5 0-.2-.1-.4-.1-.6 0-.1 0-.2-.1-.4v-.2.1c-.2-1.3-.2-2.7-.3-4.1-.2-4-.2-7.8-.1-11.7.1-4.5.2-9.1.4-13.6.2-4.2.4-8.4.6-12.5.2-2.8.3-5.6.6-8.3 0-.4.1-.7.1-1.1v-.4c0-.1-.1.5 0 .3v-.1-.1c-.1.3-.5 1.1-1 1H4c.2-.1-.4 0-.3 0h.7c3-.3 5.9-.3 8.9-.5 4.9-.2 9.8-.3 14.7-.5l17-.3c5.2 0 10.4 0 15.6.1 1.1 0 2.1.1 3.2.1.8 0 1.5-.7 1.5-1.5s-.7-1.4-1.5-1.5c-5-.2-10-.2-14.9-.2-5.7 0-11.5.1-17.2.2-5.3.2-10.5.3-15.7.5-3.5.1-7 .2-10.4.5-.8.1-1.7.1-2.5.3-1.1.3-1.2 1.1-1.3 2-.2 2.2-.4 4.5-.5 6.8-.2 4-.5 7.9-.6 11.9C.4 48 .2 52.6.1 57.2 0 61.4 0 65.6 0 69.7c.1 2.7.1 5.6.7 8.3.1.4.2.8.4 1.2.3.5.9.8 1.4.8 4.5 0 9-.1 13.4-.1 5.2 0 10.4-.1 15.6-.1 5.3 0 10.6 0 15.9-.1 4.9 0 9.8 0 14.7-.1 3.9 0 7.8-.1 11.7-.1 2.4 0 4.8 0 7.2-.1.5 0 1.2 0 1.7-.1 1.1-.3 1.2-1.3 1.2-2.3.3-3.1.4-6.2.6-9.3.3-5.1.5-10.2.7-15.4.2-5.3.4-10.7.5-16V33c.1-1.9-2.9-1.9-2.9 0z"
				fill="#f0694b"
			/>
		</svg>
	)
}

export default Graph
