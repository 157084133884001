import React from 'react'

export const Food = () => {
	return (
		<svg width="78" height="89" viewBox="0 0 78 89" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M50.0839 21.484C50.8193 19.3254 52.2595 17.477 54.1728 16.2363C59.3633 12.8702 66.3494 16.4404 66.73 22.7053C66.8637 24.906 66.2879 27.117 65.0928 28.9572L55.7928 43.2777L54.9121 43.2777L44.0425 43.2777L43.0276 42.1955L50.0839 21.484Z"
				stroke="black"
				strokeWidth="3.95503"
			/>
			<path
				d="M47.3086 32.4893L52.5428 35.131"
				stroke="black"
				strokeWidth="3.95503"
				strokeLinecap="round"
			/>
			<path
				d="M54.2266 25.5574L62.9502 29.9602"
				stroke="black"
				strokeWidth="3.95503"
				strokeLinecap="round"
			/>
			<path
				d="M68.2285 4.82642L63.3374 14.5176"
				stroke="black"
				strokeWidth="3.95503"
				strokeLinecap="round"
			/>
			<path
				d="M73.6719 11.0537L63.3606 14.4478"
				stroke="black"
				strokeWidth="3.95503"
				strokeLinecap="round"
			/>
			<path
				d="M59.9023 4.10205L63.2965 14.4133"
				stroke="black"
				strokeWidth="3.95503"
				strokeLinecap="round"
			/>
			<path
				d="M14.4889 24.7504V14.2607H22.5805V24.7504C22.5805 25.2873 22.7938 25.8023 23.1735 26.182L28.4608 31.4693C28.8405 31.849 29.0538 32.3639 29.0538 32.9009V43.3018H8.01562V32.9009C8.01562 32.3639 8.22893 31.849 8.60862 31.4693L13.8959 26.182C14.2756 25.8023 14.4889 25.2873 14.4889 24.7504Z"
				stroke="black"
				strokeWidth="3.95503"
			/>
			<path
				d="M22.5779 12.6417V8.19308C22.5779 7.07491 21.6715 6.16846 20.5533 6.16846H16.511C15.3928 6.16846 14.4863 7.07491 14.4863 8.19308V12.6417"
				stroke="black"
				strokeWidth="3.95503"
			/>
			<path
				d="M29.0512 36.9172H18.5356C16.2992 36.9172 14.4863 38.7301 14.4863 40.9665L14.4863 43.3018"
				stroke="black"
				strokeWidth="3.95503"
			/>
			<rect
				x="2.17087"
				y="43.3242"
				width="66.4016"
				height="15.5885"
				rx="2.07173"
				stroke="black"
				strokeWidth="3.95503"
			/>
			<rect
				x="2.17087"
				y="70.6863"
				width="66.4016"
				height="15.5885"
				rx="2.07173"
				stroke="black"
				strokeWidth="3.95503"
			/>
			<rect
				x="9.99314"
				y="58.9594"
				width="50.7668"
				height="11.6798"
				stroke="black"
				strokeWidth="3.95503"
			/>
			<path
				d="M23.9668 26.747C26.8194 23.7037 30.8757 21.8022 35.3761 21.8022C40.3135 21.8022 44.7164 24.0909 47.5817 27.6653"
				stroke="black"
				strokeWidth="3.95503"
			/>
			<path
				d="M43.6596 23.9406C39.106 23.4392 34.3744 24.9342 30.8831 28.4255C29.7211 29.5875 28.7802 30.8869 28.0605 32.2714"
				stroke="black"
				strokeWidth="3.95503"
			/>
			<path
				d="M34.9594 43.8983C34.8245 39.7293 36.3482 35.5165 39.5305 32.3343C41.9297 29.9351 44.9145 28.4786 48.0249 27.9649"
				stroke="black"
				strokeWidth="3.95503"
			/>
		</svg>
	)
}

export default Food
